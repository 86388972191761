import { cs, en } from 'vuetify/locale'

export const customCs = {
    ...cs,
    information: ' ',

    // General
    dialog_close: "Zavřít",
    dialog_delete: "Smazat",
    dialog_trash: "Zahodit",
    dialog_save: "Uložit",
    dialog_try_again: "Zkusit znovu",
    action_edit: "Upravit",
    action_add: "Přidat",
    action_create: "Vytvořit",
    action_test: "Otestovat",
    action_copy: "Zkopírovat",
    action_remove: "Odebrat",
    action_delete: "Odstranit",
    action_import: "Importovat",
    action_cancel: "Zrušit",
    action_choose: "Vybrat",
    action_change: "Změnit",
    action_search: "Hledat",
    role_teacher: "Učitel",
    role_student: "Student",

    // Vuetify
    badge: "Odznak",
    open: "Otevřít",
    close: "Zavřít",
    input: {
        clear: "Vyčistit",
        prependAction: "Akce",
    },

    // LoadingScreen
    error: "Chyba: {0}",
    error_network_connection: "Nejste připojeni k síti",
    error_bad_request: "Neplatný požadavek",
    error_bad_response: "Server je dočasně nedostupný",
    error_concurrent_edit: "Došlo k úpravě jiným uživatelem. Uložte si úpravy bokem a obnovte stránku.",
    error_deleting_lesson: "Chyba při mazání lekce",
    error_deleting_week: "Chyba při mazání týdne",
    error_deleting_topic: "Chyba při mazání tématu",

    // TooltipEmojiSpan
    tooltip_lesson_timed: "Včasné vyřešení do {0}",
    tooltip_lesson_locked: "Obsahuje zamknuté úlohy",
    tooltip_lesson_hidden: "Skryto před studenty",
    tooltip_module_allowed_show: "Povoleno zveřejnění",
    tooltip_module_completed: "Vyřešeno {0}",
    tooltip_module_completed_early: "Vyřešeno včas",
    tooltip_module_requested_help: "Požádáno o pomoc",
    tooltip_module_submitted: "Odesláno k ohodnocení",
    tooltip_module_solved: "Vyřešeno",
    tooltip_module_in_progress: "Rozpracováno",
    tooltip_module_opened: "Otevřeno",
    tooltip_module_unopened: "Neotevřeno",

    // SemesterList
    semester_title: "Semestr",
    semester_list: "Seznam semestrů",
    create_semester: "Vytvořit semestr",
    subjects: "Předměty",
    courses_total: "Celkem kurzů",
    semester_code: "Kód semestru",
    semester_start: "Začátek semestru",
    semester_end: "Konec semestru",
    semester_edit: "Editovat semestr",
    semester_delete: "Smazat semestr",
    semester_delete_question: "Opravdu si přejete smazat semestr",
    semester_date_required: "Je potřeba zadat datum",
    semester_cant_delete_courses_present: "Semestr obsahuje aspoň jeden kurz a proto ho nelze smazat",
    semester_creation_title: "Vytvoření semestru",
    semester_creation_success_text: "Semestr úspěšně vytvořen",
    semester_edit_title: "Editace semestru",
    semester_edit_success_text: "Semestr úspěšně editován",
    semester_deletion_title: "Smazání semestru",
    semester_deletion_success_text: "Semestr úspěšně smazán",

    // Guide
    guides: "Návody",
    guide_list: "Seznam návodů",
    chapters: "Kapitoly",
    chapter_list:"Seznam kapitol",
    guide_not_found: "Návod nebyl nalezen",


    // UserMenu
    user_menu_admin_button: "Správce",
    user_menu_sandbox_button: "Pískoviště",
    user_menu_guarantor_button: "Garant",
    user_menu_teacher_button: "Učitel",
    user_menu_anonymous_mode: "Anonymní režim",
    user_menu_dark_mode: "Tmavý režim",
    user_menu_switch_language: "Use english",
    user_menu_switch_account: "Přepnout účet",
    user_menu_logout: "Odhlásit se",

    //TopicList
    topic_list_title: "Témata",
    topic_list_empty: "Nejsou dostupná žádná témata",
    topic_list_create: "Vytvořit nové téma",
    topic_list_modules: "{0} modulů",

    //TopicListDeleteDialog
    topic_list_delete_title: "Smazat téma",
    topic_list_delete_confirm: "Opravdu chcete smazat téma",
    topic_list_delete_success_title: "Téma smazáno",
    topic_list_delete_success_text: "Téma bylo úspěšně smazáno.",

    //TopicListEditDialog
    topic_edit_dialog_create: "Vytvořit téma",
    topic_edit_dialog_edit: "Upravit téma",
    topic_edit_name: "Název tématu",
    topic_edit_success_title: "Téma upraveno",
    topic_edit_success_text: "Téma bylo úspěšně upraveno.",

    // TopicListEditCreateDialog
    topic_edit_notification_title: "Téma upraveno",
    topic_edit_notification_text: "Téma bylo úspěšně upraveno.",
    topic_create_notification_title: "Téma vytvořeno",
    topic_create_notification_text: "Téma bylo úspěšně vytvořeno.",

    // CourseList
    course_list_title: "Kurzy",
    course_list_current_lessons: "Probíhající lekce",
    course_list_empty: "Nejsi členem žádného kurzu. Požádej cvičícího o zapsání do kurzu.",
    course_join: "Připojit se ke kurzu",
    course_join_short: "Připojit se",
    course_join_code: "Kód",
    course_join_invalid_code: "Neplatný kód",
    course_list_role_teacher: " (Učitel)",
    course_list_role_student: " ",
    course_list_lesson_completed: "{0} / {1} lekcí",
    course_list_semesters: "Kurzy ({0})",
    course_list_all_semesters: "Všechny",

    // CurrentLessonList
    current_lesson_list_title: "Probíhající lekce",
    current_lesson_list_empty: "Aktuálně neprobíhají žádné lekce",

    // CourseDetail
    course_detail_title: "Lekce",
    course_detail_left_drawer_course_list: "Přehled kurzů",
    course_detail_header_course_secret: "Zobrazit kód",
    course_detail_header_users: "Uživatelé",
    course_detail_header_new_lesson: "Nová lekce",
    course_detail_no_weeks: "Tento kurz neobsahuje žádné týdny.",
    course_detail_week_lesson_order_change: "Přesunout lekci",
    course_detail_week_lesson_order_changed_title: "Lekce přesunuta",
    course_detail_week_lesson_order_changed_text: "Lekce byla úspěšně přesunuta.",
    course_detail_week_lesson_order_changed_error_title: "Chyba při přesunu lekce",
    course_detail_week_lesson_order_changed_error_text: "Při přesunu lekce nastala chyba: {0}",
    hidden_lesson_count: "Týden obsahuje skryté lekce",
    hidden_module_count: "Lekce obsahuje skryté moduly",

    // CourseDetailDeleteDialog
    course_detail_lesson_delete_title: "Smazat lekci",
    course_detail_lesson_delete_confirm: "Opravdu si přejete smazat lekci",
    course_detail_lesson_delete_success_title: "Lekce smazána",
    course_detail_lesson_delete_success_text: "Lekce byla úspěšně smazána.",
    course_detail_week_delete_title: "Smazat týden",
    course_detail_week_delete_confirm: "Opravdu si přejete smazat týden",
    course_detail_week_delete_success_title: "Týden smazán",
    course_detail_week_delete_success_text: "Týden byl úspěšně smazán.",

    // CourseDetailCopyDialog
    course_detail_lesson_copy_title: "Zkopírovat lekci",
    course_detail_lesson_copy_tooltip: "Zkopírovat do kurzu",
    course_detail_lesson_copy_success_title: "Lekce zkopírována",
    course_detail_lesson_copy_success_text: "Lekce byla úspěšně zkopírována.",
    course_detail_week_copy_title: "Zkopírovat týden",
    course_detail_week_copy_tooltip: "Zkopírovat do kurzu",
    course_detail_week_copy_success_title: "Týden zkopírován",
    course_detail_week_copy_success_text: "Týden byl úspěšně zkopírován.",

    // CourseDeleteDialog
    course_delete_title: "Smazat kurz",
    course_delete_confirm: "Opravdu si přejete smazat kurz",
    course_delete_success_title: "Kurz smazán",
    course_delete_success_text: "Kurz byl úspěšně smazán.",
    error_deleting_course: "Chyba při mazání kurzu",

    //CourseCreateEdit
    course_create_edit_title:"Informace",
    course_create_new:"Nový kurz",
    nav_create_course: "Nový kurz",
    course_create_edit_name:"Název",
    course_create_edit_short_name:"Krátký název",
    course_create_edit_teachers_all:"Všichni cvičící",
    course_create_edit_teachers:"Cvičící",
    course_create_edit_add_teachers:"Přidat cvičících",
    course_create_edit_code:"Heslo pro připojení",
    course_create_edit_public:"Veřejný kurz",
    course_create_edit_no_users:"Žadné uživatele nejsou",

    course_edit_notification_title: "Kurz upraven",
    course_edit_notification_text: "Úpravy provedené v kurzu byly uloženy.",
    course_create_notification_title: "Kurz vytvořen",
    course_create_notification_text: "Kurz byl úspěšně vytvořen.",

    // CourseDetailLessonRow
    course_detail_lesson_row_student_list: "Přehled studentů a modulů",

    // CourseDeleteDialog
    course_delete: "Smazat kurz",
    course_delete_question: "Opravdu si přejete smazat kurz",
    course_deletion_title: "Smazání kurzu",
    course_cant_delete_weeks_present: "Kurz obsahuje aspoň jeden týden a proto ho nelze smazat",
    course_cant_delete_users_present: "Kurz obsahuje aspoň jednoho uživatele a proto ho nelze smazat",
    course_deletion_success_text: "Kurz úspěšně smazán",

    // LessonDetailLeftDrawer
    lesson_detail_intro_text: "Úvodní text",
    module_title_locked: "Zamknutý modul",

    // ModuleUnlockCard
    module_locked_prompt: "Tento modul je zamknutý. Pro odemknutí zadej kód:",
    module_locked_code: "Kód",
    module_locked_unlock: "Odemknout",
    module_student_locked: "Student tento modul ještě neodemknul",
    module_locked_invalid_code: "Nesprávný kód",
    module_locked_depends: "Tento modul se odemkne až po splnění modulu \"{0}\"",

    // LessonModuleDetail
    tab_assignment: "ZADÁNÍ",
    tab_rating: "Hodnocení studentů",
    tab_rating_empty: "Žadná hodnocení ještě nejsou",
    rating_btn: "TVOJE HODNOCENÍ",
    tab_preview: "NÁHLED",
    tab_editor: "Editor",
    tab_error: "CHYBA",
    tab_empty_text: "V učitelském módu se textové moduly neukazují.",
    tab_not_allowed_share: "Student nepovolil anonymizované zveřejnění svého řešení.",

    //ModuleRating
    rating_edit_notification_title: "Hodnocení upraveno",
    rating_edit_notification_text: "Úpravy provedené v hodnocení byly uloženy.",
    rating_create_notification_title: "Hodnocení vytvořeno",
    rating_create_notification_text: "Hodnocení bylo úspěšně vytvořeno.",
    rating_edit_dialog_edit: "Vyjádřete svůj názor",

    // Subjects
    subject_list: "Předměty",
    subject_list_drawer: "Semestry",
    subject_detail_list: "Předměty",
    subject_course_num: "kurz(ů)",
    subject_new_course_button: "Nový kurz",
    subject_course_short_name: "Paralelka",

    subject_detail_left_drawer_subject_list: "Přehled předmětů",
    //SubjectCreateEdit
    subject_create_edit_title:"Informace",
    subject_create_new:"Nový předmět",
    nav_create_subject: "Nový předmět",
    subject_create_edit_name:"Název",
    subject_create_edit_no_guarantors: "Tento předmět neobsahuje žádné garanty.",
    subject_create_edit_guarantors_all:"Všichni garanti",
    subject_create_edit_guarantors_name:"Jméno",
    subject_create_edit_guarantors_username:"Username",
    subject_create_edit_guarantors:"Garanti",
    subject_create_edit_add_guarantors:"Přidat garanty",
    subject_create_edit_code:"Kod",
    subject_create_edit_no_users:"Žadné uživatele nejsou",

    subject_edit_notification_title: "Předmět upraven",
    subject_edit_notification_text: "Úpravy provedené v předmětu byly uloženy.",
    subject_create_notification_title: "Předmět vytvořen",
    subject_create_notification_text: "Předmět byl úspěšně vytvořen.",

    subject_none:  "V tomto semestru není žádný předmět.",
    subject_delete: "Smazání předmětu",
    subject_delete_question: "Opravdu si přejete smazat předmět",
    subject_delete_courses_present: "Předmět obsahuje aspoň jeden kurz a proto ho nelze smazat",

    // LessonCreateEdit
    unsaved_changes_dialog_title: "Neuložené změny",
    unsaved_changes_dialog_text: "Máte neuložené změny. Chcete je zahodit, nebo uložit?",
    lesson_menu_title_create: "Vytvořit lekci",
    lesson_menu_title_edit: "Upravit lekci",
    lesson_menu_detail: "Detail lekce",
    lesson_edit_name: "Jméno",
    lesson_edit_order: "Pořadí",
    lesson_edit_code: "Kód pro odemknutí",
    lesson_edit_type: "Typ",
    lesson_edit_time_start: "Začátek",
    lesson_edit_time_end: "Konec",
    lesson_edit_hidden: "Skrýt před studenty",

    lesson_edit_tab_information: "Informace",
    lesson_edit_tab_text: "Úvodní text",
    lesson_edit_tab_modules: "Moduly",

    lesson_edit_modules_sandbox_not_exist: "Pískoviště neexistuje, požádejte správce nebo garanta, aby vám ho vytvořil.",
    lesson_edit_modules_sandbox_empty: "Žádný týden nebo lekce v pískovišti, kam by bylo možné přidat moduly",
    lesson_edit_modules_sandbox_success: "Modul byl úspěšně přidán do pískoviště",
    lesson_edit_modules_sandbox_title: "Testovani modulu",


    lesson_edit_modules_remove: "Odebrat z lekce",
    lesson_edit_modules_empty: "Žádné moduly",
    lesson_edit_modules_add: "Přidat existující modul",
    lesson_edit_modules_create: "Vytvořit nový modul",
    lesson_edit_modules_read: "Zobrazit modul",

    lesson_edit_notification_title: "Lekce upravena",
    lesson_edit_notification_text: "Úpravy provedené v lekci byly uloženy.",
    lesson_create_notification_title: "Lekce vytvořena",
    lesson_create_notification_text: "Lekce byla úspěšně vytvořena.",

    lesson_type_tutorial: "Lekce",
    lesson_type_tutorial_preparation: "Příprava k lekci",
    lesson_type_individual_task: "Samostatná práce",
    lesson_type_supplementary: "Doplňkový materiál",
    lesson_type_frequent_mistakes: "Časté chyby",
    lesson_type_information: "Informace",

    // CourseDetailWeekCreateEdit
    week_edit_notification_title: "Týden upraven",
    week_edit_notification_text: "Úpravy provedené v týdnu byly uloženy.",
    week_create_notification_title: "Týden vytvořen",
    week_create_notification_text: "Týden byl úspěšně vytvořen.",

    week_edit_dialog_create: "Vytvořit týden",
    week_edit_dialog_edit: "Upravit týden",
    week_edit_name: "Jméno",
    week_edit_from: "Začátek",
    week_edit_until: "Konec",
    week_new: "Nový týden",

    // ModuleCreateEdit
    module_menu_title_create: "Vytvořit modul",
    module_menu_title_edit: "Upravit modul",

        module_edit_discussion: "Diskuze",
    module_create_comment_success: "Váš komentář byl úspěšně přídán",
    module_create_comment_title: "Vytvoření komentáře",
    module_comment_input: "Napište svůj názor",
    module_comment_name: "Komentář",
    module_delete_comment_success: "Váš komentář byl úspěšně smazán",
    module_create_comment_too_long: "Váš komentář je příliš dlouhý nebo kratký",
    module_delete_title: "Smazání komentáře",
    module_delete_question: "Opravdu chcete smazat svůj komentář",
    module_show_more: "UKAŽ VÍCE KOMENTÁŘŮ",
    module_show_less: "UKAŽ MÉNĚ KOMENTÁŘŮ",
    module_update_title: "Úprava komentáře",
    module_update_title_success: "Váš komentář byl úspěšně úpraven!",

    module_edit_name: "Jméno",
    module_edit_type: "Typ",
    module_type_text: "Textový modul",
    module_type_code: "Kódový modul",
    module_type_quiz: "Kvízový modul",
    module_type_assignment: "Assignment modul",
    module_type_selftest: "Samotestový modul",
    module_edit_depend: "Závislost",
    module_edit_no_depend: "(Žádná závislost)",
    module_edit_author: "Autor",
    module_edit_editors: "Spolueditoři",
    module_edit_editors_all: "Všichni učitelé",
    module_edit_difficulty: "Obtížnost",
    module_difficulty_none: "Bez hodnocení obtížnosti",
    module_difficulty_beginner: "Začátečník",
    module_difficulty_easy: "Lehký",
    module_difficulty_medium: "Střední",
    module_difficulty_difficult: "Těžký",
    module_difficulty_extreme: "Expert",
    module_edit_subject: "Předměty",
    module_edit_topic: "Téma",
    module_edit_min_percent: "Minimální počet procent pro splnění úlohy: {0} %",
    module_edit_settings: "Nastavení modulu",
    module_edit_lockable: "Zamykatelný 🔑",
    module_edit_time_limit: "Časový limit ⏱️",
    module_edit_manual_eval: "Manuální vyhodnocení",
    module_edit_hidden: "Skryto před studenty 👻",

    module_edit_tab_information: "Informace",
    module_edit_tab_assignment: "Zadání",

    module_edit_notification_title: "Modul upraven",
    module_edit_notification_text: "Úpravy provedené v modulu byly uloženy.",
    module_create_notification_title: "Modul vytvořen",
    module_create_notification_text: "Modul byl úspěšně vytvořen.",

    // ModuleList
    module_list_add_title: "Přidání modulu",
    module_list_header_id: "Identifikátor",
    module_list_header_name: "Název",
    module_list_header_author: "Autor",
    module_list_header_type: "Typ",
    module_list_header_topics: "Témata",
    module_list_header_subjects: "Předměty",
    module_list_header_topics_selected: "Vybráno {0}",
    module_list_header_subjects_selected: "Vybráno {0}",
    module_list_header_action: "Akce",
    module_list_empty: "Žádné moduly k přidání",
    module_list_search_empty: "Žádné moduly neodpovídají vašemu vyhledávání",
    module_list_topics_empty: "Žádná témata",
    module_list_topics_subjects: "Žádné předměty",
    module_list_clear_filters: "Vymazat filtry",

    // CourseUserList
    course_user_delete_dialog_title : "Odebrat uživatele",
    course_user_delete_dialog_text: "Opravdu chcete odebrat uživatele&nbsp;<strong>{0}</strong>&nbsp;z kurzu?",
    course_user_delete_success_title: "Odebrání úspěšné",
    course_user_delete_success_text: "Uživatel byl úspěšně odebrán z kurzu.",
    course_user_delete_error_title: "Chyba při mazání",
    course_user_delete_error_text: "Při odebírání uživatele z kurzu došlo k chybě: {0}",

    course_secret_change_title: "Změnit kód",
    course_secret_changed_title: "Kód změněn",
    course_secret_changed_text: "Kód pro připojení ke kurzu byl úspěšně změněn.",

    course_users: "Uživatelé",
    course_import_users: "Import uživatelů",

    course_user_table_name: "Jméno",
    course_user_table_username: "Username",
    course_user_table_role: "Role",
    course_user_table_progress: "Pokrok",
    course_user_table_action: "Akce",
    course_user_remove: "Odebrat z kurzu",
    course_user_detail_link: "Detail uživatele",
    course_user_list_left_drawer_users: "Přehled uživatelů",

    // CourseUserImport
    course_user_import_title: "Import uživatelů",
    course_user_import_label: "Zkopírujte z KOSu / Progtestu seznam uživatelů a vložte je zde ve formátu: name;username;role",
    course_user_imported_title: "Import úspěšný",
    course_user_imported_text: "Uživatelé byli úspěšně přidáni do kurzu.",

    // LessonUserList
    lesson_user_list_title: "Přehled studentů",
    lesson_user_list_header_user: "Uživatel",
    lesson_user_list_header_solution: "Řešení modulu",
    lesson_user_list_header_detail: "Detail",
    lesson_user_list_filter_completed: "Ukázat pouze úspěšná řešení",
    lesson_user_list_filter_requests: "Ukázat pouze žádosti",
    lesson_user_list_filter_allowed_show: "Ukázat pouze zveřejněná řešení",
    lesson_user_list_row_detail: "Detail uživatele",

    // LessonUserListLeftDrawer
    lesson_user_list_left_drawer_users: "Přehled řešení",
    lesson_user_list_left_drawer_module: "Detail modulu",
    lesson_user_list_empty: "Zadaný filtr nesplňuje žádný uživatel",
    lesson_user_list_previous: "Předchozí",
    lesson_user_list_next: "Další",

    // NotificationMenu
    notification_menu_title: "Notifikace",
    notification_show_satisfied: "Ukázat i vyřešené",
    notification_hide_satisfied: "Schovat vyřešené",
    notification_list_empty: "✨ Nemáte žádné nepřečtené notifikace",
    notification_list_all_empty: "✨ Nemáte žádné notifikace",
    notification_list_mark_satisfied: "Označit vše jako přečtené",
    notification_list_show_all: "Všechny notifikace",

    notification_help: "😸 {0} odpověděl na tvou žádost o pomoc u úlohy <strong>{1}</strong>: {2}",
    notification_comment: "💬 {0} okomentoval tvé řešení úlohy <strong>{1}</strong>: {2}",
    notification_evaluate: "⬇️ {0} ohodnotil tvé řešení úlohy <strong>{1}</strong>: {2}",
    notification_help_teacher: "😿 {0} požádal o pomoc u úlohy <strong>{1}</strong>: {2}",
    notification_evaluate_teacher: "⬆️ {0} požádal o ohodnocení úlohy <strong>{1}</strong>: {2}",
    notification_comment_teacher: "💬 {0} bylo okomentováno jeho řešení úlohy <strong>{1}</strong>: {2}",

    // AppFooter
    trainer_footer_title: "Trainer – podpora výuky",
    trainer_footer_report: "Nahlásit problém",
    trainer_version: "Verze",

    // Nav
    nav_users: "Uživatelé",
    nav_import_users: "Import uživatelů",
    nav_create_lesson: "Nová lekce",
    nav_lesson_user_list: "{0} (Uživatelé)",
    nav_create_module: "Nový modul",
    nav_notification_list: "Všechny notifikace",

    // Separate pages
    presentation_page_title: "Prezentace",
    guide_page_title: "Příručka",

    // LoginForm
    login: "Přihlásit se",
    login_ctu: "Přihlášení ČVUT účtem",
    presentation_link: "Prezentace",

    // Requests
    pending_request_eval: "Čeká na ohodnocení učitelem",
    pending_request_help: "Žádost o pomoc čeká na odpověď",
    pending_request_eval_teacher: "Nezodpovězená žádost o ohodnocení: {0}",
    pending_request_help_teacher: "Nezodpovězená žádost o pomoc: {0}",

    request_eval_btn: "Odevzdat",
    request_eval_cancel_btn: "Zrušit odevzdání",
    request_help_btn: "Požádat o pomoc",
    request_help_cancel_btn: "Zrušit žádost",
    request_load_fail: "Chyba při načítání žádostí",
    request_load_text_fail: "Při načítání žádostí došlo k chybě: {0}",
    request_teacher_eval_btn: "Ohodnotit",
    request_teacher_comment_btn: "Okomentovat",
    request_teacher_help_btn: "Odpovědět",
    request_teacher_answer: "{0} odpověděl: {1}",
    request_answer_delete_btn: "Smazat odpověď",
    request_answer_deleted_title: "Odpověď smazána",
    request_answer_deleted_title_fail: "Chyba při mazání odpovědi",
    request_answer_deleted_text: "Odpověď byla úspěšně smazána.",
    request_answer_deleted_text_fail: "Při pokusu o smazání odpovědi došlo k chybě: {0}",

    //Logs
    log_button: "Logy",
    log_list: "Seznam logů",
    log_delete: "Smazat logy",
    log_delete_older_than: "Smazat logy starší než",
    log_date_required: "Je potřeba zadat datum",
    log_delete_title: "Smazaní logů",
    log_delete_success_text: "Logy byly uspěšně smazany",

    request_help: {
        title: "Žádost odeslána",
        title_fail: "Chyba při odesílání",
        text: "Žádost o pomoc byla úspěšně odeslána.",
        text_fail: "Při pokusu o odeslání žádosti o pomoc došlo k chybě: {0}",

        dialog_title: "Žádost o pomoc",
        textarea: "Text žádosti",
        action: "Odeslat",

        cancel_title: "Žádost zrušena",
        cancel_title_fail: "Chyba při rušení žádosti",
        cancel_text: "Žádost o pomoc byla úspěšně zrušena.",
        cancel_text_fail: "Při rušení žádosti o pomoc došlo k chybě: {0}",
    },
    request_teacher_help: {
        title: "Žádost vyřešena",
        title_fail: "Chyba při odesílání",
        text: "Žádost o pomoc byla úspěšně vyřešena.",
        text_fail: "Při pokusu o vyřešení žádosti o pomoc došlo k chybě: {0}",

        dialog_title: "Odpovědět na žádost o pomoc",
        textarea: "Odpověď",
        action: "Odpovědět",
    },
    request_eval: {
        title: "Úloha odevzdána",
        title_fail: "Chyba při odevzdávání",
        text: "Úloha byla úspěšně odevzdána k ohodnocení učitelem.",
        text_fail: "Při pokusu o odevzdání úlohy došlo k chybě: {0}",

        dialog_title: "Odevzdat úlohu",
        textarea: "Komentář",
        action: "Odevzdat",

        cancel_title: "Žádost zrušena",
        cancel_title_fail: "Chyba při rušení žádosti",
        cancel_text: "Žádost o ohodnocení byla úspěšně zrušena.",
        cancel_text_fail: "Při rušení žádosti o ohodnocení došlo k chybě: {0}",
    },
    request_teacher_eval: {
        title: "Úloha ohodnocena",
        title_fail: "Chyba při odesílání",
        text: "Žádost o ohodnocení byla úspěšně vyřešena.",
        text_fail: "Při pokusu o ohodnocení úlohy došlo k chybě: {0}",

        dialog_title: "Ohodnotit úlohu",
        textarea: "Hodnocení",
        percent: "Hodnocení: {0} %",
        action: "Ohodnotit",
        toggle: "Označit úlohu jako vyřešenou",
    },
    request_teacher_comment: {
        title: "Úloha okomentována",
        title_fail: "Chyba při odesílání",
        text: "Úloha byla úspěšně okomentována.",
        text_fail: "Při pokusu o okomentování úlohy došlo k chybě: {0}",

        dialog_title: "Okomentovat úlohu",
        textarea: "Komentář",
        percent: "Hodnocení: {0} %",
        action: "Okomentovat",
        toggle: "Označit úlohu jako vyřešenou",
    },

    // CodeModuleBottomOverlay
    code_module: {
        allowed_show: {
            title: "Zveřejnění úspěšné",
            title_fail: "Zveřejnění neúspěšné",
            text: "Anonymizované zveřejnění kódu bylo úspěšné.",
            text_fail: "Při anonymizovaném zveřejňování kódu došlo k chybě: {0}",
        },
        disallowed_show: {
            title: "Zrušení zveřejnění úspěšné",
            title_fail: "Zrušení zveřejnění neúspěšné",
            text: "Zrušení anonymizovaného zveřejnění kódu bylo úspěšné",
            text_fail: "Při rušení anonymizovaného zveřejnění kódu došlo k chybě: {0}",
        },

        allow_show_tooltip: "Povolit anonymizované zveřejnění",
        disallow_show_tooltip: "Zrušit anonymizované zveřejnění",
        file_upload: "Kód (.c, .cpp, .cxx, .h, .hpp, .hxx)",
        code_inserted_title: "Vložení kódu úspěšné",
        code_inserted_text: "Kód byl úspěšně vložen, probíhá testování...",
        code_inserted_failure_title: "Vložení kódu neúspěšné",
        code_inserted_failure_text: "Systém nebyl schopen rozpoznat, který soubor se snažíte nahrát.\nNázev nahraného souboru musí končit stejně, jako název souboru v editoru,\nnapř. student.cpp -> cokolivxyz-student.cpp",
        download: "Stáhnout",
        default_code: "Výchozí kód",
        submitted_code: "Odevzdaný kód",
        reference_solution: "Referenční řešení",
        test_data: "Testovací data",
        reset_progress: "Resetovat progress",
        test_name_tooltip: "Název testovací sady",
        tests_passed_total_tooltip: "Počet splněných testů v sadě",
        tests_show_outputs: "Zobrazit výstupy",
        tests_show_error: "Zobrazit chybu",
        tests_description: "Popis:",
        terminate: "Zastavit",
        run_tests: "Otestovat",
        tab_input: "Vstup",
        tab_comparison: "Srovnání",
        tab_compilation: "Chyba kompilace",
        tab_student: "Student",
        tab_reference: "Reference",
        test_compilation: "Kompilace",
        test_compilation_info: "Provede kompilaci a slinkování kódu.",
        character_limit: "Znaků: {0} / {1}",
        edit_module: "Upravit modul",
        module_solutions: "Zobrazit řešení modulu",
        tab_teacher: "TESTER",
        code_comment: "Komentář",
        create_code_comment: "Napsat komentář",

        error_compiling: "Chyba při kompilaci",
        error_code_too_large: "Kód je příliš dlouhý ({0} / {1} znaků)",
        error_linking: "Chyba při linkování",
        error_output: "Výstup",
        error_ret_code_not_ok: "Program skončil s nenulovým návratovým kódem {0}",
        error_should_fail_didnt_fail: "Program byl chybný, ale studentovy asserty neodhalily chybu.",
        error_shouldnt_fail_did_fail: "Program byl správný, ale některý ze studentových assertů selhal.",
        error_invalid_output: "Nesprávný výstup",
        error_memory_not_freed: "Program uvolnil pouze {0} / {1} bloků dynamicky alokované paměti.",
        error_program_timeout: "Studentský program překročil povolenou dobu běhu, {0}.",

        edit_tab_information: "Kódový modul",
        edit_tab_tests: "Testy",
        edit_tab_student: "Výchozí kód",
        edit_tab_envelope: "Obálka kódu",
        edit_tab_teacher: "Testovací kód",

        edit_code_type: "Typ kódu",
        type_showcase: "Ukázka kódu",
        type_test_io: "Standardní I/O test",
        type_test_assert: "Assertový test",
        type_write_assert: "Napiš asserty",

        edit_interaction: "Interakce",
        interaction_editor: "Editor a nahrávání kódu",
        interaction_updown: "Pouze nahrávání kódu",

        code_limit_small: "Malý (1 kB)",
        code_limit_medium: "Střední (5 kB)",
        code_limit_large: "Velký (10 kB)",

        edit_file_limit: "Limit na velikost nahraného souboru",
        file_limit_small: "Malý (10 kB)",
        file_limit_medium: "Střední (40 kB)",
        file_limit_large: "Velký (80 kB)",

        edit_envelope_type: "Obálka kódu",
        env_type_c: "C kód (bez I/O)",
        env_type_c_io: "C kód, přesměrované I/O",
        env_type_cpp: "C++ kód (bez STL)",
        env_type_cpp_stl: "C++ kód (včetně STL)",
        env_type_custom: "Vlastní obálka",

        edit_library: "Knihovna",
        library_type_none: "Žádná",
        library_type_c: "C knihovna",
        library_type_cpp: "C++ knihovna",

        edit_reference_public: "Referenční řešení je stáhnutelné",

        edit_hide_compiler_output: "Schovat výstup kompilátoru. Student vidí pouze zda kompilace proběhla neúspěšně.",

        file_label_showcase: "Ukázkové vstupy (.in) v archivu .tar",
        file_label_test_io: "Testovací vstupy (.in, .ref) v archivu .tar",

        edit_test_name: "Název testu",
        edit_test_description: "Popis testu",
        edit_test_parameter: "Parametr",
        edit_test_time_limit: "Časový limit (prázdný = bez limitu)",
        edit_test_check_memory: "Kontrolovat práci s pamětí",
        edit_test_hidden: "Skrýt výsledky testu",
        edit_test_should_fail: "Program má selhat",
        delete_test: "Smazat test",
        create_test: "Přidat test",
        delete_test_title: "Test smazán",
        delete_test_text: "Test byl úspěšně smazán.",
        new_test: "Nový test",
        create_test_requirements: "Pro vytvoření testu musíš zadat jeho jméno, popis a parametr",

        edit_file_name: "Název souboru",
        edit_file_code_limit: "Limit na velikost nahraného kódu",
        edit_file_content: "Výchozí obsah souboru",
        edit_file_header_file: "Hlavičkový soubor (nekompilovat)",
        edit_file_reference: "Referenční řešení",
        delete_file: "Smazat soubor",
        create_file: "Přidat soubor",
        delete_file_title: "Soubor smazán",
        delete_file_text: "Soubor byl úspěšně smazán.",
        new_file: "Nový soubor",
        create_file_requirements: "Pro vytvoření souboru musíš zadat jeho jméno a obsah",
    },

    //QuizModule
    quiz_module: {
        create_new_quiz_tab: "Kvízový editor",
        question_list: "Seznam otázek",
        add_existing_question: "Přidat existující otázku",
        create_new_question : "přidat novou otázku",
        create_new_quiz_heading: "Tvorba kvízu",
        question: "Otázka",
        new_question_dialog_heading: "Nová otázka",
        edit_question_dialog_heading: "Úprava otázky",
        chosen_question_type: "Vybraný typ otázky",
        tooltip_truefalse: "Položte otázku resp. výrok a vyberte zda je pravdivý nebo ne",
        tooltip_multichoice: "Položte otázku a následně zadejte studentům možnosti ze kterých budou moci vybírat",
        option: "Možnost",
        correct_option: "Správne?",
        add_option: "Přidat možnost",
        time_limit: "Časový limit (sekundy)",
        explanation: "Vysvětlení",

        question_list_add_title: "Přidat otazku",
        question_list_header_id: "Identifikátor",
        question_list_header_text: "Text otazky",
        question_list_header_author: "Autor",
        question_list_header_type: "Typ",
        question_list_header_topics: "Témata",
        question_list_header_subjects: "Předměty",
        question_list_header_topics_selected: "Vybráno {0}",
        question_list_header_subjects_selected: "Vybráno {0}",
        question_list_header_action: "Akce",
        question_list_empty: "Žádné otazky k přidání",
        question_list_search_empty: "Žádné otazky neodpovídají vašemu vyhledávání",
        question_list_topics_empty: "Žádná témata",
        question_list_topics_subjects: "Žádné předměty",
        question_list_clear_filters: "Vymazat filtry",

        create_question: "Vytvořit otázku",
        edit_question: "Upravit otázku",

        quiz : "Kvíz",
        no_questions_dialog_heading: "Tento kvíz neobsahuje žádné otázky",
        no_questions_dialog_text: "Před spuštěním kvízu do něj dejte alespoň jednu otázku",
        create_room: "Vytvořit místnost",
        enter_room: "Vstoupit",
        wrong_code_dialog: "Špatný kód",
        wrong_code_dialog_text: "Zkontroluj kód a zkus znovu. Popřípadě zkontroluj, zda zadáváš kód od správného kvízu, pokud jich máte v lekci více.",
        enter_code_placeholder: "Zadej kód - (cvičící ho pravděpodobně promítá)",

        waiting_room: "Čekárna",
        waiting_room_joke: "Rychle si projdi, co ses měl na toto cvičení naučit",
        room_password: "Heslo",
        number_of_students: "Počet studentů: ",
        students: "Studenti",
        launch_quiz: "Spustit kvíz",

        next_question: "Další otázka",
        end_quiz: "Ukončit kvíz",

        results: "Výsledky",
        review: "Náhled",
        leave: "Odejít",

        points: "Body",
        student_passed: "Prošel",
        student_didnt_pass: "Neprošel",

        fix_eval: "Opravit hodnocení",
        reasign_points: "Přepočítat body",
        go_back: "Zpět",
        question_edited_allert_heading: "Otázka upravena",
        question_edited_allert_text: "Bodové hodnocení upraveno u studentů:",
        room_id: "ID místnosti",
    },

    //SelftesModule
    selftest_module: {
        create_new_selftest_tab: "Selftestový editor",
        selftest_heading_info: "Zde můžete vložit otázky, ze kterých si studenti budou moci generovat testy na procičování.",

        selftest: "Samotest",
        number_of_questions: "Počet otázek",
        too_many_questions: "Príliš veľa otázok",
        invalid_number_of_questions: "Neplatný počet otázok",
        time_difficulty: "Časová náročnost",
        launch: "Spustit",
        to_review: "Náhled",
        leave: "Ukončit",
        question: "Otázka",
        question_status_positive: "Vyplnená",
        question_status_negative: "Nevyplnená",
        to_question: "K otázke",
        hand_in: "Odovzdať",
        correctly_answered: "Správne zodpovedaných",
        success_rate: "Úspešnosť",
        to_start: "K hlavnej obrazovke",

        attempt: "Pokus",
        quizroom: "Miestnosť",
    },

    // AssignmentModule
    assignment_module: {
        error_file_too_large: "Soubor je příliš velký (max. velikost je 1 MB)",
        input_label: "Obrázek (.jpg, .jpeg, .png)",
    },

    // Template
    template_title: "Šablona",
    template_module_test_title: "Použít testy z modulu",
    template_module_test_button: "Zkopírovat testy z jiného modulu",
    template_create_button: "Vytvořit šablonu",
    template_use_button: "Použít šablonu",
    template_create_notification_title: "Template Created",
    template_create_notification_text: "Your template has been successfully created.",
    template_update_notification_title: "Template Updated",
    template_update_notification_text: "Your template has been successfully updated."
}

export const customEn = {
    ...en,
    information: ' ',

    // General
    dialog_close: "Close",
    dialog_delete: "Delete",
    dialog_trash: "Trash",
    dialog_save: "Save",
    dialog_try_again: "Try again",
    action_edit: "Edit",
    action_add: "Add",
    action_create: "Create",
    action_test: "Test",
    action_copy: "Copy",
    action_remove: "Remove",
    action_delete: "Delete",
    action_import: "Import",
    action_cancel: "Cancel",
    action_choose: "Choose",
    action_change: "Change",
    action_search: "Search",
    role_teacher: "Teacher",
    role_student: "Student",

    // Vuetify
    badge: "Badge",
    open: "Open",
    close: "Close",
    input: {
        clear: "Clear",
        prependAction: "Action",
    },

    // LoadingScreen
    error: "Error: {0}",
    error_network_connection: "You're not connected to the network",
    error_bad_request: "Invalid request",
    error_bad_response: "Server is not available at the moment",
    error_concurrent_edit: "Another user edited before you did. Save the changes aside and refresh the page.",
    error_deleting_lesson: "Error deleting lesson",
    error_deleting_week: "Error deleting week",
    error_deleting_topic: "Error deleting topic",

    // TooltipEmojiSpan
    tooltip_lesson_timed: "Timed completion until {0}",
    tooltip_lesson_locked: "Contains locked tasks",
    tooltip_lesson_hidden: "Hidden from students",
    tooltip_module_allowed_show: "Allowed to show",
    tooltip_module_completed: "Completed on {0}",
    tooltip_module_completed_early: "Completed early",
    tooltip_module_requested_help: "Help requested",
    tooltip_module_submitted: "Submitted for evaluation",
    tooltip_module_solved: "Solved",
    tooltip_module_in_progress: "In progress",
    tooltip_module_opened: "Opened",
    tooltip_module_unopened: "Not opened",

    // SemesterList
    semester_title: "Semesters",
    semester_list: "Semester list",
    create_semester: "Create semester",
    subjects: "Subjects",
    courses_total: "Course count",
    semester_code: "Semester code",
    semester_start: "Start of the semester",
    semester_end: "End of the semester",
    semester_edit: "Edit semester",
    semester_delete: "Delete semester",
    semester_delete_question: "Do you really wish to delete semester",
    semester_date_required: "Date is required",
    semester_cant_delete_courses_present: "Semester has at least one course so it can't be deleted",
    semester_creation_title: "Semester creation",
    semester_creation_success_text: "Semester created successfully",
    semester_edit_title: "Semester editing",
    semester_edit_success_text: "Semester edited successfully",
    semester_deletion_title: "Semester deletion",
    semester_deletion_success_text: "Semester deleted successfully",

    // Guide
    guides: "Guides",
    guide_list: "Guide list",
    chapters: "Chapters",
    chapter_list:"Chapter list",
    guide_not_found: "Guide not found",

    // UserMenu
    user_menu_admin_button: "Admin",
    user_menu_sandbox_button: "Sandbox",
    user_menu_guarantor_button: "Guarantor",
    user_menu_teacher_button: "Teacher",
    user_menu_anonymous_mode: "Anonymous mode",
    user_menu_dark_mode: "Dark mode",
    user_menu_switch_language: "Použít češtinu",
    user_menu_switch_account: "Switch account",
    user_menu_logout: "Logout",

    //TopicList
    topic_list_title: "Topics",
    topic_list_empty: "There are no topics yet.",
    topic_list_create: "Create a topic",
    topic_list_modules: "{0} modules",

    //TopicListDeleteDialog
    topic_list_delete_title: "Delete topic",
    topic_list_delete_confirm: "Do you really want to delete the topic",
    topic_list_delete_success_title: "Topic deleted",
    topic_list_delete_success_text: "The topic has been successfully deleted.",

    //TopicListEditDialog
    topic_edit_dialog_create: "Create topic",
    topic_edit_dialog_edit: "Edit topic",
    topic_edit_name: "Topic name",
    topic_edit_success_title: "Topic modified",
    topic_edit_success_text: "The topic has been successfully edited.",


    topic_edit_notification_title: "Topic edited",
    topic_edit_notification_text: "Topic has been edited successfully.",
    topic_create_notification_title: "Topic created",
    topic_create_notification_text: "Topic has been created successfully.",


    // CourseList
    course_list_title: "Courses",
    course_list_current_lessons: "Ongoing lessons",
    course_list_empty: "You are not a member of any course. Ask the teacher to add you to a course.",
    course_join: "Join a course",
    course_join_short: "Join",
    course_join_code: "Code",
    course_join_invalid_code: "Invalid code",
    course_list_role_teacher: " (Teacher)",
    course_list_role_student: " ",
    course_list_lesson_completed: "{0} / {1} lessons",
    course_list_semesters: "Courses ({0})",
    course_list_all_semesters: "All",

    // CurrentLessonList
    current_lesson_list_title: "Ongoing lessons",
    current_lesson_list_empty: "No lessons currently ongoing",

    // CourseDetail
    course_detail_title: "Lessons",
    course_detail_left_drawer_course_list: "Course list",
    course_detail_header_course_secret: "Show code",
    course_detail_header_users: "Users",
    course_detail_header_new_lesson: "New lesson",
    course_detail_no_weeks: "This course has no weeks.",
    course_detail_week_lesson_order_change: "Move lesson",
    course_detail_week_lesson_order_changed_title: "Lesson moved",
    course_detail_week_lesson_order_changed_text: "Lesson has been moved successfully.",
    course_detail_week_lesson_order_changed_error_title: "Error whilst moving lesson",
    course_detail_week_lesson_order_changed_error_text: "An error occurred whilst trying to move lesson: {0}",
    hidden_lesson_count: "Week contains hidden lessons",
    hidden_module_count: "Lesson contains hidden modules",

    // CourseDetailDeleteDialog
    course_detail_lesson_delete_title: "Delete lesson",
    course_detail_lesson_delete_confirm: "Do you really want to delete the lesson",
    course_detail_lesson_delete_success_title: "Lesson deleted",
    course_detail_lesson_delete_success_text: "The lesson has been successfully deleted.",
    course_detail_week_delete_title: "Delete week",
    course_detail_week_delete_confirm: "Do you really want to delete the week",
    course_detail_week_delete_success_title: "Week deleted",
    course_detail_week_delete_success_text: "The week has been successfully deleted.",

    // CourseDetailCopyDialog
    course_detail_lesson_copy_title: "Copy lesson",
    course_detail_lesson_copy_tooltip: "Copy to course",
    course_detail_lesson_copy_success_title: "Lesson copied",
    course_detail_lesson_copy_success_text: "The lesson has been successfully copied.",
    course_detail_week_copy_title: "Copy week",
    course_detail_week_copy_tooltip: "Copy to course",
    course_detail_week_copy_success_title: "Week copied",
    course_detail_week_copy_success_text: "The week has been successfully copied.",

    // CourseDetailLessonRow
    course_detail_lesson_row_student_list: "Overview of students and modules",

    // CourseDeleteDialog
    course_delete: "Delete course",
    course_delete_question: "Do you really wish to delete course",
    course_deletion_title: "Course deletion",
    course_deletion_success_text: "Course deleted successfully",
    course_cant_delete_weeks_present: "Course has at least one week so it can't be deleted",
    course_cant_delete_users_present: "Course has at least one users so it can't be deleted",

    // LessonDetailLeftDrawer
    lesson_detail_intro_text: "Introduction text",
    module_title_locked: "Locked module",

    // ModuleUnlockCard
    module_locked_prompt: "This module is locked. To unlock, enter the code:",
    module_locked_code: "Code",
    module_locked_unlock: "Unlock",
    module_student_locked: "The student hasn't unlocked this module yet",
    module_locked_invalid_code: "Invalid code",
    module_locked_depends: "This module will unlock upon completion of module \"{0}\"",

    // LessonModuleDetail
    tab_assignment: "ASSIGNMENT",
    tab_rating: "Student Rating",
    tab_rating_empty: "No ratings yet",
    rating_btn: "YOUR RATING",
    tab_preview: "PREVIEW",
    tab_editor: "Editor",
    tab_error: "ERROR",
    tab_empty_text: "Text modules are not shown in teacher mode.",
    tab_not_allowed_share: "Student has not allowed publishing of his solution.",

    //ModuleRating
    rating_edit_notification_title: "Rating edited",
    rating_edit_notification_text: "Rating has been edited successfully.",
    rating_create_notification_title: "Rating created",
    rating_create_notification_text: "Rating has been created successfully.",
    rating_edit_dialog_edit: "Express your opinion",


    // Subjects
    subject_list: "Subjects",
    subject_list_drawer: "Semesters",
    subject_detail_list: "Subjects",
    subject_course_num: "course(s)",
    subject_new_course_button: "New course",
    subject_course_short_name: "Parallel",

    subject_detail_left_drawer_subject_list: "Subject list",

    //SubjectCreateEdit
    subject_create_edit_title:"Information",
    subject_create_new:"New subject",
    nav_create_subject: "New subject",
    subject_create_edit_name:"Name",
    subject_create_edit_no_guarantors: "This subject has no guarantors.",
    subject_create_edit_guarantors_all:"All guarantors",
    subject_create_edit_guarantors_name:"Name",
    subject_create_edit_guarantors_username:"Username",
    subject_create_edit_guarantors:"Guarantors",
    subject_create_edit_add_guarantors:"Add guarantors",
    subject_create_edit_code:"Code",
    subject_create_edit_no_users:"No users",

    subject_edit_notification_title: "Subject edited",
    subject_edit_notification_text: "Changes made in this subject were saved.",
    subject_create_notification_title: "Subject created",
    subject_create_notification_text: "Subject has been created successfully.",

    subject_none: "There is no subjects in this semester.",

    subject_delete: "Delete subject",
    subject_delete_courses_present: "Subject has at least one course so it can't be deleted",
    subject_delete_question: "Do you really wish to delete subject",

    // LessonCreateEdit
    unsaved_changes_dialog_title: "Unsaved changes",
    unsaved_changes_dialog_text: "You have unsaved changes. Do you want to trash them, or save them?",
    lesson_menu_title_create: "Create lesson",
    lesson_menu_title_edit: "Edit lesson",
    lesson_menu_detail: "Lesson detail",
    lesson_edit_name: "Name",
    lesson_edit_order: "Order",
    lesson_edit_code: "Unlock code",
    lesson_edit_type: "Type",
    lesson_edit_time_start: "Start time",
    lesson_edit_time_end: "End time",
    lesson_edit_hidden: "Hide from students",

    lesson_edit_tab_information: "Information",
    lesson_edit_tab_text: "Introduction text",
    lesson_edit_tab_modules: "Modules",

    lesson_edit_modules_sandbox_not_exist: "Sandbox does not exist, ask admin or guarantor to create one for you",
    lesson_edit_modules_sandbox_empty: "No week or lesson in sandbox to add modules to",
    lesson_edit_modules_sandbox_success: "Module added to sandbox",
    lesson_edit_modules_sandbox_title: "Module testing",

    lesson_edit_modules_remove: "Remove from lesson",
    lesson_edit_modules_empty: "No modules",
    lesson_edit_modules_add: "Add existing module",
    lesson_edit_modules_create: "Create new module",
    lesson_edit_modules_read: "Read module",

    lesson_edit_notification_title: "Lesson edited",
    lesson_edit_notification_text: "Changes made in this lesson were saved.",
    lesson_create_notification_title: "Lesson created",
    lesson_create_notification_text: "Lesson has been created successfully.",

    lesson_type_tutorial: "Lesson",
    lesson_type_tutorial_preparation: "Lesson preparation",
    lesson_type_individual_task: "Individual task",
    lesson_type_supplementary: "Supplementary material",
    lesson_type_frequent_mistakes: "Frequent mistakes",
    lesson_type_information: "Information",

    // CourseDetailWeekCreateEdit
    week_edit_notification_title: "Week edited",
    week_edit_notification_text: "Changes made to this week have been saved.",
    week_create_notification_title: "Week created",
    week_create_notification_text: "Week has been created successfully.",

    week_edit_dialog_create: "Create week",
    week_edit_dialog_edit: "Edit week",
    week_edit_name: "Name",
    week_edit_from: "Start",
    week_edit_until: "End",
    week_new: "New week",

    // ModuleCreateEdit
    module_menu_title_create: "Create module",
    module_menu_title_edit: "Edit module",

    module_edit_discussion: "Discussion",
    module_create_comment_success: "Your comment was successfully added!",
    module_create_comment_title: "Comment creation",
    module_comment_input: "Write your opinion",
    module_comment_name: "Comment",
    module_delete_comment_success: "Your comment was successfully deleted!",
    module_create_comment_too_long: "Your comment is too long or too short",
    module_delete_title: "Delete comment",
    module_delete_question: "Do you really want to delete your comment",
    module_show_more: "SHOW MORE COMMENTS",
    module_show_less: "SHOW LESS COMMENTS",
    module_update_title: "Updating comment",
    module_update_title_success: "Your comment was successfully updated!",

    module_edit_name: "Name",
    module_edit_type: "Type",
    module_type_text: "Text module",
    module_type_code: "Code module",
    module_type_quiz: "Quiz module",
    module_type_assignment: "Assignment module",
    module_type_selftest: "Selftest module",
    module_edit_depend: "Dependency",
    module_edit_no_depend: "(No dependency)",
    module_edit_author: "Author",
    module_edit_editors: "Editors",
    module_edit_editors_all: "All teachers",
    module_edit_difficulty: "Difficulty",
    module_difficulty_none: "No difficulty rating",
    module_difficulty_beginner: "Beginner",
    module_difficulty_easy: "Easy",
    module_difficulty_medium: "Medium",
    module_difficulty_difficult: "Difficult",
    module_difficulty_extreme: "Expert",
    module_edit_subject: "Subjects",
    module_edit_topic: "Topic",
    module_edit_min_percent: "Minimum percent for completing the task: {0} %",
    module_edit_settings: "Module settings",
    module_edit_lockable: "Lockable",
    module_edit_time_limit: "Time limit",
    module_edit_manual_eval: "Manual evaluation",
    module_edit_hidden: "Hidden from students",

    module_edit_tab_information: "Information",
    module_edit_tab_assignment: "Assignment",

    module_edit_notification_title: "Module edited",
    module_edit_notification_text: "Changes made to the module have been saved.",
    module_create_notification_title: "Module created",
    module_create_notification_text: "Module has been created successfully.",

    // ModuleList
    module_list_add_title: "Add module",
    module_list_header_id: "Identifier",
    module_list_header_name: "Name",
    module_list_header_author: "Author",
    module_list_header_type: "Type",
    module_list_header_topics: "Topics",
    module_list_header_subjects: "Subjects",
    module_list_header_topics_selected: "{0} selected",
    module_list_header_subjects_selected: "{0} selected",
    module_list_header_action: "Action",
    module_list_empty: "No modules to add",
    module_list_search_empty: "No modules correspond to your search",
    module_list_topics_empty: "No topics",
    module_list_subjects_empty: "No subjects",
    module_list_clear_filters: "Clear filters",

    // CourseUserList
    course_user_delete_dialog_title : "Remove user",
    course_user_delete_dialog_text: "Do you really wish to remove user&nbsp;<strong>{0}</strong>&nbsp;from course?",
    course_user_delete_success_title: "Removal successful",
    course_user_delete_success_text: "User has been removed from course successfully.",
    course_user_delete_error_title: "Error whilst removing",
    course_user_delete_error_text: "An error occured whilst trying to remove user from course: {0}",

    course_secret_change_title: "Change code",
    course_secret_changed_title: "Code changed",
    course_secret_changed_text: "Join code has been changed successfully.",

    course_users: "Users",
    course_import_users: "Import users",

    course_user_table_name: "Name",
    course_user_table_username: "Username",
    course_user_table_role: "Role",
    course_user_table_progress: "Progress",
    course_user_table_action: "Action",
    course_user_remove: "Remove from course",
    course_user_detail_link: "User detail",
    course_user_list_left_drawer_users: "User list",

    // CourseUserImport
    course_user_import_title: "Import users",
    course_user_import_label: "Copy user list from KOS / Progtest and insert them here in format: name;username;role",
    course_user_imported_title: "Import successful",
    course_user_imported_text: "Users have been imported to the course.",

    course_delete_title: "Remove course",
    course_delete_confirm: "Do you really want to delete the course",
    course_delete_success_title: "Course deleted",
    course_delete_success_text: "Course was successfully deleted",
    error_deleting_course: "Error deleting course",

    course_create_edit_title:"Information",
    course_create_new:"New course",
    nav_create_course: "New course",
    course_create_edit_name:"Name",
    course_create_edit_short_name:"Short name",
    course_create_edit_teachers_all:"All teachers",
    course_create_edit_teachers:"Teachers",
    course_create_edit_add_teachers:"Add teachers",
    course_create_edit_code:"Access code",
    course_create_edit_public:"Public course",
    course_create_edit_no_users:"No users",

    course_edit_notification_title: "Course edited",
    course_edit_notification_text: "Changes made to the course have been saved.",
    course_create_notification_title: "Course created",
    course_create_notification_text: "Course has been created successfully.",

    // LessonUserList
    lesson_user_list_title: "Student list",
    lesson_user_list_header_user: "User",
    lesson_user_list_header_solution: "Module solution",
    lesson_user_list_header_detail: "Detail",
    lesson_user_list_filter_completed: "Show only successful solutions",
    lesson_user_list_filter_requests: "Show only requests",
    lesson_user_list_filter_allowed_show: "Show only published solutions",
    lesson_user_list_row_detail: "User detail",

    // LessonUserListLeftDrawer
    lesson_user_list_left_drawer_users: "Solution list",
    lesson_user_list_left_drawer_module: "Module detail",
    lesson_user_list_empty: "No users satisfies the filter",
    lesson_user_list_previous: "Previous",
    lesson_user_list_next: "Next",

    // NotificationMenu
    notification_menu_title: "Notifications",
    notification_show_satisfied: "Show satisfied",
    notification_hide_satisfied: "Hide satisfied",
    notification_list_empty: "✨ You have no unread notifications",
    notification_list_all_empty: "✨ You have no notifications",
    notification_list_mark_satisfied: "Mark all as satisfied",
    notification_list_show_all: "All notifications",

    notification_help: "😸 {0} answered to your help request of task <strong>{1}</strong>: {2}",
    notification_comment: "💬 {0} commented your solution of task <strong>{1}</strong>: {2}",
    notification_evaluate: "⬇️ {0} evaluated your solution of task <strong>{1}</strong>: {2}",
    notification_help_teacher: "😿 {0} asked for help with task <strong>{1}</strong>: {2}",
    notification_evaluate_teacher: "⬆️ {0} asked for evaluation of task <strong>{1}</strong>: {2}",
    notification_comment_teacher: "💬 You commented to student {0} his solution of task <strong>{1}</strong>: {2}",

    // AppFooter
    trainer_footer_title: "Trainer – education support",
    trainer_footer_report: "Report problem",
    trainer_version: "Version",

    // Nav
    nav_users: "Users",
    nav_import_users: "Import users",
    nav_create_lesson: "New lesson",
    nav_lesson_user_list: "{0} (Users)",
    nav_create_module: "New module",
    nav_notification_list: "All notifications",

    // Separate pages
    presentation_page_title: "Presentation",
    guide_page_title: "Guide",

    // LoginForm
    login: "Log in",
    login_ctu: "Log in with CTU account",
    presentation_link: "Presentation",

    // Requests
    pending_request_eval: "Pending evaluation by teacher",
    pending_request_help: "Help request awaiting response",
    pending_request_eval_teacher: "Unanswered evaluation request: {0}",
    pending_request_help_teacher: "Unanswered help request: {0}",

    request_eval_btn: "Submit",
    request_eval_cancel_btn: "Cancel submission",
    request_help_btn: "Request help",
    request_help_cancel_btn: "Cancel request",
    request_load_fail: "Error loading student request",
    request_load_text_fail: "An error occurred while loading the last student request: {0}",
    request_teacher_eval_btn: "Evaluate",
    request_teacher_comment_btn: "Comment",
    request_teacher_help_btn: "Answer",
    request_teacher_answer: "{0} answered: {1}",
    request_answer_delete_btn: "Delete answer",
    request_answer_deleted_title: "Answer deleted",
    request_answer_deleted_title_fail: "Error deleting answer",
    request_answer_deleted_text: "The answer has been deleted.",
    request_answer_deleted_text_fail: "An error occurred while deleting the answer: {0}",

    //Logs
    log_button: "Logs",
    log_list: "Log list",
    log_delete: "Delete logs",
    log_delete_older_than: "Delete logs older than",
    log_date_required: "Date is required",
    log_delete_title: "Log deletion",
    log_delete_success_text: "Logs were deleted",

    request_help: {
        title: "Request sent",
        title_fail: "Error sending request",
        text: "The help request was successfully sent.",
        text_fail: "An error occurred while attempting to send a help request: {0}",

        dialog_title: "Help request",
        textarea: "Request text",
        action: "Send",

        cancel_title: "Request canceled",
        cancel_title_fail: "Error canceling request",
        cancel_text: "The help request was successfully canceled.",
        cancel_text_fail: "An error occurred while canceling the help request: {0}",
    },
    request_teacher_help: {
        title: "Request resolved",
        title_fail: "Error submitting request",
        text: "Help request has been resolved successfully.",
        text_fail: "An error occured while attempting to resolve the help request: {0}",

        dialog_title: "Resolve help request",
        textarea: "Answer",
        action: "Answer",
    },
    request_eval: {
        title: "Assignment submitted",
        title_fail: "Error submitting assignment",
        text: "The assignment was successfully submitted for evaluation by the teacher.",
        text_fail: "An error occurred while attempting to submit the assignment: {0}",

        dialog_title: "Submit assignment",
        textarea: "Comment",
        action: "Submit",

        cancel_title: "Request canceled",
        cancel_title_fail: "Error canceling request",
        cancel_text: "The request for evaluation was successfully canceled.",
        cancel_text_fail: "An error occurred while canceling the request for evaluation: {0}",
    },
    request_teacher_eval: {
        title: "Assignment evaluated",
        title_fail: "Error whilst evaluating",
        text: "Assignment has been successfully evaluated.",
        text_fail: "An error occurred whilst trying to evaluate assignment: {0}",

        dialog_title: "Evaluate Assignment",
        textarea: "Evaluation",
        percent: "Evaluate: {0} %",
        action: "Evaluate",
        toggle: "Mark assignment as completed",
    },
    request_teacher_comment: {
        title: "Assignment commented",
        title_fail: "Error whilst sending comment",
        text: "Assignment has been successfully commented.",
        text_fail: "An error occurred whilst trying to comment assignment: {0}",

        dialog_title: "Comment Assignment",
        textarea: "Comment",
        percent: "Evaluate: {0} %",
        action: "Comment",
        toggle: "Mark assignment as completed",
    },

    // CodeModuleBottomOverlay
    code_module: {
        allowed_show: {
            title: "Successful publication",
            title_fail: "Publication unsuccessful",
            text: "Anonymous code publication was successful.",
            text_fail: "An error occurred during anonymous code publication: {0}",
        },
        disallowed_show: {
            title: "Unpublication successful",
            title_fail: "Unpublication unsuccessful",
            text: "Anonymous unpublication of code was successful.",
            text_fail: "An error occurred during the unpublication of anonymous code: {0}",
        },
        allow_show_tooltip: "Allow anonymous publication",
        disallow_show_tooltip: "Cancel anonymous publication",
        file_upload: "Code (.c, .cpp, .cxx)",
        code_inserted_title: "Code insertion successful",
        code_inserted_text: "The code was successfully inserted, testing is in progress...",
        code_inserted_failure_title: "Code insertion failed",
        code_inserted_failure_text: "System wasn't able to determine, which file you are trying to upload.\nUploaded file's name must end the same as a file name in editor\n(e.g. student.cpp -> whateverxyz-student.cpp)",
        download: "Download",
        default_code: "Default code",
        submitted_code: "Submitted code",
        reference_solution: "Reference solution",
        test_data: "Test data",
        reset_progress: "Reset progress",
        test_name_tooltip: "Test set name",
        tests_passed_total_tooltip: "Number of tests passed in the set",
        tests_show_outputs: "Show outputs",
        tests_show_error: "Show error",
        tests_description: "Description:",
        terminate: "Terminate",
        run_tests: "Run tests",
        tab_input: "Input",
        tab_comparison: "Comparison",
        tab_compilation: "Compilation error",
        tab_student: "Student",
        tab_reference: "Reference",
        test_compilation: "Compilation",
        test_compilation_info: "Compiles and links code.",
        character_limit: "{0} / {1} characters",
        edit_module: "Edit module",
        module_solutions: "Show module solutions",
        tab_teacher: "TESTER",
        code_comment: "Comment",
        create_code_comment: "Write a comment",

        error_compiling: "Compilation error",
        error_code_too_large: "The code is too long ({0} / {1} characters)",
        error_linking: "Linking error",
        error_output: "Output",
        error_ret_code_not_ok: "Program ended with a non-zero return code {0}",
        error_should_fail_didnt_fail: "The program was faulty, but the student's asserts did not reveal the error.",
        error_shouldnt_fail_did_fail: "The program was correct, but one of the student's asserts failed.",
        error_invalid_output: "Invalid output",
        error_memory_not_freed: "The program only freed {0} / {1} dynamically allocated memory blocks.",
        error_program_timeout: "The program exceeded the time limit, {0}.",

        edit_tab_information: "Code module",
        edit_tab_tests: "Tests",
        edit_tab_student: "Default code",
        edit_tab_envelope: "Code envelope",
        edit_tab_teacher: "Test code",

        edit_code_type: "Code type",
        type_showcase: "Showcase",
        type_test_io: "Standard I/O test",
        type_test_assert: "Assert test",
        type_write_assert: "Write asserts",

        edit_interaction: "Interaction",
        interaction_editor: "Code editing and uploading",
        interaction_updown: "Code uploading only",

        code_limit_small: "Small (1 kB)",
        code_limit_medium: "Medium (5 kB)",
        code_limit_large: "Large (10 kB)",

        edit_file_limit: "Limit for uploaded file size",
        file_limit_small: "Small (10 kB)",
        file_limit_medium: "Medium (40 kB)",
        file_limit_large: "Large (80 kB)",

        edit_envelope_type: "Code envelope",
        env_type_c: "C code (no I/O)",
        env_type_c_io: "C code, redirected I/O",
        env_type_cpp: "C++ code (no STL)",
        env_type_cpp_stl: "C++ code (with STL)",
        env_type_custom: "Custom envelope",

        edit_library: "Library",
        library_type_none: "None",
        library_type_c: "C library",
        library_type_cpp: "C++ library",

        edit_reference_public: "Reference solution is downloadable",

        edit_hide_compiler_output: "Hide compiler output, only show student whether or not compilation was succesful.",

        file_label_showcase: "Sample inputs (.in) in .tar archive",
        file_label_test_io: "Test inputs (.in, .ref) in .tar archive",

        edit_test_name: "Test name",
        edit_test_description: "Test description",
        edit_test_parameter: "Parameter",
        edit_test_time_limit: "Time limit (empty = no limit)",
        edit_test_check_memory: "Check memory access",
        edit_test_hidden: "Hide test outputs",
        edit_test_should_fail: "Test should fail",
        delete_test: "Delete test",
        create_test: "Add test",
        delete_test_title: "Test deleted",
        delete_test_text: "Test has been deleted successfully.",
        new_test: "New test",
        create_test_requirements: "The new test must contain non-empty name, description and parameter",

        edit_file_name: "File name",
        edit_file_code_limit: "Limit for uploaded code size",
        edit_file_content: "Default file content",
        edit_file_header_file: "Header file (do not compile)",
        edit_file_reference: "Reference solution",
        delete_file: "Delete file",
        create_file: "Create file",
        delete_file_title: "File deleted",
        delete_file_text: "File has been successfully deleted.",
        new_file: "New file",
        create_file_requirements: "The new file must contain non-empty name and content",
    },

    // AssignmentModule
    assignment_module: {
        error_file_too_large: "File is too large (max. size is 1 MB)",
        input_label: "Image (.jpg, .jpeg, .png)",
    },

    //QuizModule
    quiz_module: {
        create_new_quiz_tab: "Quiz editor",
        question_list: "List of questions",
        add_existing_question: "Add existing question",
        create_new_question : "create new question",
        create_new_quiz_heading: "Your quiz",
        question: "Question",
        new_question_dialog_heading: "New Question",
        edit_question_dialog_heading: "Edit Question",
        chosen_question_type: "Chosen question type",
        tooltip_truefalse: "Enter a question or statement and select if its true or not",
        tooltip_multichoice: "Enter a question and number of options for students to choose from",
        option: "Option",
        correct_option: "Correct?",
        add_option: "Add option",
        time_limit: "Time limit (seconds)",
        explanation: "Explanation",

        question_list_add_title: "Add question",
        question_list_header_id: "Identifier",
        question_list_header_text: "Question text",
        question_list_header_author: "Author",
        question_list_header_type: "Type",
        question_list_header_topics: "Topics",
        question_list_header_subjects: "Subjects",
        question_list_header_topics_selected: "{0} selected",
        question_list_header_subjects_selected: "{0} selected",
        question_list_header_action: "Action",
        question_list_empty: "No questions to add",
        question_list_search_empty: "No questions correspond to your search",
        question_list_topics_empty: "No topics",
        question_list_subjects_empty: "No subjects",
        question_list_clear_filters: "Clear filters",

        create_question: "create question",
        edit_question: "edit question",
        quiz : "Quiz",
        no_questions_dialog_heading: "This quiz doesn't contain any questions",
        no_questions_dialog_text: "Please add at least one question before running the quiz",
        create_room: "Create quizroom",
        enter_room: "Enter",
        wrong_code_dialog: "Wrong code",
        wrong_code_dialog_text: "Check the the passcode and try again. In case that no code seems to be working, check if you are in trying to join the correct quiz if you have more of them in this lesson.",
        enter_code_placeholder: "Enter password here - (teacher is probably projecting it)",

        waiting_room: "Waiting room",
        waiting_room_joke: "Quickly run through everything you should have learned for today",
        room_password: "Password",
        number_of_students: "Number of students: ",
        students: "Students",
        launch_quiz: "Launch quiz",

        next_question: "Next question",
        end_quiz: "End quiz",

        results: "Results",
        review: "Review",
        leave: "Leave",

        points: "Points",
        student_passed: "Passed",
        student_didnt_pass: "Didn't pass",

        fix_eval: "Fix evaluation",
        reasign_points: "Reassign points",
        go_back: "Go back",
        question_edited_allert_heading: "Question edited",
        question_edited_allert_text: "Point assignment was changed. Affected students:",
        room_id: "Room ID"

    },

    //SelftesModule
    selftest_module: {
        create_new_selftest_tab: "Selftest editor",
        selftest_heading_info: "Here you can insert from which students can generate practice tests.",
        selftest: "Selftest",
        number_of_questions: "Number of questions",
        too_many_questions: "Too many questions",
        invalid_number_of_questions: "Invalid number of questions",
        time_difficulty: "Time difficulty",
        launch: "Launch",
        to_review: "To review",
        leave: "Leave",
        question: "Question",
        question_status_positive: "Filled in",
        question_status_negative: "Not filled in",
        to_question: "To question",
        hand_in: "Submit",
        correctly_answered: "Correctly answered",
        success_rate: "Success rate",
        to_start: "Back to main screen",

        attempt: "Attempt",
        quizroom: "Room",

    },

    //Template
    template_title: "Templates",
    template_module_test_title: "Use tests from module",
    template_module_test_button: "Copy tests from another module",
    template_create_button: "Create template",
    template_use_button: "Use template",
    template_create_notification_title: "Template Created",
    template_create_notification_text: "Your template has been successfully created.",
    template_update_notification_title: "Template Updated",
    template_update_notification_text: "Your template has been successfully updated."
}
